<template>
  <div class="counter">
    <Chip
      :color="chipColor"
      :clickeable="true"
      size="big"
      class="chip"
      icon=""
      @clicked="onClick"
    >
      <template v-slot:content>
        <template v-if="!isEmpty">
          <div
            class="heading-display score"
            :class="scoreColor"
            v-text="score"
          />
          <div
            class="content-title scale"
            :class="scaleColor"
            v-text="`/${scale}`"
          />
        </template>
        <template v-else>
          <div class="heading-display" :class="iconClass" />
        </template>
      </template>
    </Chip>
    <p class="content-title">{{ handleMonthText }}</p>
  </div>
</template>

<script>
import { Chip } from '@seliaco/red-panda'

export default {
  name: 'Counter',
  components: {
    Chip
  },
  props: {
    score: Number,
    scale: Number,
    month: Number,
    type: String,
    metadata: Array
  },
  data () {
    return {
      isEmpty: false,
      lang: this.$store.getters.language.lang,
      currentMonth: new Date().getMonth() === parseInt(this.month) - 1
    }
  },
  created () {
    if (!this.metadata?.length) {
      this.isEmpty = true
    }
  },
  computed: {
    chipColor () {
      if (this.currentMonth) {
        return 'purple-outline'
      } else if (!this.isEmpty) {
        return 'white-outline'
      } else {
        return 'white'
      }
    },
    scoreColor () {
      return this.currentMonth ? 'text-purple' : 'text-gray-60'
    },
    scaleColor () {
      return this.currentMonth ? 'text-purple-50' : 'text-gray-50'
    },
    iconClass () {
      return this.currentMonth
        ? 'icon icon-plus text-purple'
        : 'icon icon-minus'
    },
    handleMonthText () {
      const propMonth = new Date(new Date().getFullYear(), this.month - 1)
      return propMonth.toLocaleString(`${this.lang}`, { month: 'short' })
    }
  },
  methods: {
    onClick () {
      this.$emit('clicked')
    }
  }
}
</script>

<style lang="sass" scoped>
.counter
  display: flex
  justify-content: center
  flex-direction: column
  align-items: center
  .content-title
    text-align: center
    text-transform: capitalize
  .chip
    box-sizing: border-box
    align-items: baseline
    padding: 8px 12px
    gap: 3px
    min-width: 80px
    height: 50px
    border-width: 2px
    justify-content: center
    .icon
      align-self: center
    .scale
      align-self: end
    .score
      align-self: center
    .content-title
      line-height: 25px
</style>
