<template>
  <div>
    <PageContainer
      :title="$translations['check-in'].title"
      :back="actions.back"
      :has-shy-header="true"
      :ellipsis="true"
    >
      <template v-slot:content>
        <ChipsGroup
          @change="setValue"
          :options-data="options"
          :type="$route.query.type"
          :code="checkinType"
        />
        <!-- skeleton -->
        <div v-if="loading" class="page-segment">
          <div class="page-segment-content padding-all">
            <Skeleton width="110px" height="21px" border-radius="8px" />
            <div class="checkin-skeleton">
              <Skeleton
                v-for="index in 4"
                :key="index"
                width="80px"
                height="50px"
                border-radius="16px"
              />
            </div>
            <div class="checkin-skeleton">
              <Skeleton
                class="margin-top-xl"
                width="448px"
                height="48px"
                border-radius="8px"
              />
            </div>
          </div>
        </div>

        <!-- content -->
        <div v-if="!loading && counters.length > 0" class="page-segment">
          <div class="page-segment-content">
            <Progress :counters="counters" @openModal="openModal" />
          </div>
        </div>
      </template>
    </PageContainer>
    <CheckInModal v-if="showModal" :data="modalData" @close="closeModal" />
  </div>
</template>

<script>
import {
  PageContainer,
  ChipsGroup,
  CheckIn,
  Skeleton
} from '@seliaco/red-panda'
import Progress from '@/views/check-in/components/Progress'
import CheckInModal from '@/views/check-in/modals/CheckInModal'
import { CheckInEvent } from '@/types/events'

export default {
  name: 'CheckIn',
  components: {
    Skeleton,
    PageContainer,
    Progress,
    ChipsGroup,
    CheckInModal
  },
  props: {
    form: Array
  },
  mounted () {
    if (!localStorage.getItem('SliderCheckInCompleted')) {
      this.$router.push({ name: 'SlideCheckIn' })
    }
    if (this.$route.query.showModal === 'visible') {
      this.showModal = true
    }
    this.checkinType = this.$route.query.type
    this.getValues(this.checkinType)
  },
  data () {
    return {
      loading: false,
      counters: [],
      actions: {
        back: {
          callback: () => {
            this.$router.push({
              name: 'Home'
            })
          }
        }
      },
      options: [
        {
          code: 'WELLNESS',
          text: this.$translations['check-in'].type.wellness
        },
        {
          code: 'ANXIETY',
          text: this.$translations['check-in'].type.anxiety
        }
      ],
      showModal: false,
      showCarrousel: false,
      localModalData: {},
      checkinType: null
    }
  },
  computed: {
    modalData () {
      return this.$route.query.showModal === 'visible'
        ? JSON.parse(this.$route.query.data)
        : this.localModalData
    }
  },
  methods: {
    setValue (value) {
      this.$router.replace({
        query: {
          ...this.$route.query,
          type: value
        }
      })
      this.getValues(value)
    },
    getValues (checkinType) {
      this.callSegmentEvent(checkinType)
      this.loading = true
      const body = {
        type: checkinType
      }
      this.checkinType = checkinType
      CheckIn.startWellnessCheckIn(body).then(() => {
        CheckIn.getWellnessCheckInData(body.type)
          .then((response) => {
            this.counters = response
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    closeModal () {
      this.$router.replace({
        query: {
          ...this.$route.query,
          showModal: 'invisible'
        }
      })
      this.showModal = false
    },
    openModal (counterId) {
      CheckIn.getCheckInAnswerData(counterId)
        .then((response) => {
          this.showModal = true
          this.localModalData = {
            ...response,
            month:
              this.$moment.locale(this.$store.getters.language.lang) &&
              this.$moment.months(response.month - 1)
          }
        })
        .catch((e) => {
          this.$toast({
            text: e.message || this.$translations.error.default,
            severity: 'error'
          })
        })
    },
    callSegmentEvent (value) {
      CheckInEvent.Checkin_Click_Tab({ tab: value })
    }
  },
  watch: {
    $route: {
      handler (route) {
        if (route.query.showModal === 'visible') {
          this.showModal = true
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="sass" scoped>
.checkin-skeleton
  display: flex
  margin-top: 30px
  gap: 10px

::v-deep .chips-group
  padding: 0 16px

@media (min-width: 480px)
  ::v-deep .chips-group
    padding: 0
</style>
