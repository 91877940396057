<template>
  <div class="progress">
    <div
      class="heading-small"
      v-text="$translations['check-in']['section-title']"
    />
    <div class="progress-track" id="track">
      <Counter
        v-for="(counter, index) in countersLocal"
        :key="index"
        :month="Number(counter.month)"
        :score="score(counter.score)"
        :scale="Number(counter.scale)"
        :metadata="counter.metadata"
        :type="counter.type"
        @clicked="handleStartAction(counter, 'Month_Button')"
      />
    </div>
    <Button
      :text="handleBtnText"
      :disable="!isAvailable"
      @clicked="
        handleStartAction(
          countersLocal[countersLocal.length - 1],
          'Purple_Button'
        )
      "
    />
  </div>
</template>

<script>
import { Button } from '@seliaco/red-panda'
import { CheckInEvent } from '@/types/events'
import Counter from '@/views/check-in/components/Counter.vue'

export default {
  name: 'Progress',
  components: {
    Button,
    Counter
  },
  props: {
    counters: Array
  },
  data () {
    return {
      countersLocal: [],
      isAvailable: false,
      days: 0,
      hours: 0,
      dt: this.$luxon.DateTime
    }
  },
  created () {
    this.init()
  },
  mounted () {
    const track = document.getElementById('track')
    track.scrollTo(track.scrollWidth, 0)
  },
  methods: {
    init () {
      this.countersLocal = this.counters.sort((a, b) => {
        return (
          this.dt.fromISO(a.created_at).toMillis() -
          this.dt.fromISO(b.created_at).toMillis()
        )
      })

      this.isCurrentMonth()
    },
    isCurrentMonth () {
      const currentMonth = new Date().getMonth()
      const lastMonth =
        parseInt(this.countersLocal[this.countersLocal.length - 1].month) - 1
      const lastScore = this.countersLocal.at(-1).score
      this.isAvailable = currentMonth === lastMonth

      if (lastScore) {
        this.isAvailable = false

        const currentDate = this.dt.now()
        const nextMonth = currentDate.plus({ months: 1 }).startOf('month')
        this.days = Math.floor(
          nextMonth.diff(currentDate, 'days').toObject().days
        )
        this.hours = nextMonth.diff(currentDate, 'hour').toFormat('hh:mm')
      }
    },
    handleStartAction (counter, origin) {
      if (counter.status === 'FILL') {
        this.callSegmentEvent(counter)
        this.$emit('openModal', counter.id)
      } else {
        this.callSegmentEvent(counter, origin)
        this.$router.push({
          name: 'CheckInForm',
          query: {
            counterData: JSON.stringify(counter)
          }
        })
      }
    },
    callSegmentEvent (counter, origin) {
      const month = new Date(
        new Date().getFullYear(),
        counter.month - 1
      ).toLocaleString(`${this.$store.getters.language.lang}`, {
        month: 'short'
      })

      let score = counter.score

      if (!counter.score) {
        score = '+'
      }

      const body = {
        user: this.$store.getters['auth/user'],
        month: month,
        month_number: counter.month,
        type: counter.type,
        score: score
      }

      if (origin) {
        CheckInEvent.Checkin_Click_Start({ ...body, cta: origin })
      } else {
        CheckInEvent.Checkin_Click_Open(body)
      }
    }
  },
  computed: {
    handleBtnText () {
      if (this.isAvailable) {
        return this.$translations['check-in'].button.begin
      }

      if (this.days === 0) {
        return `${this.$translations['check-in'].button.available} ${this.hours} ${this.$translations['check-in'].button.hours} `
      }

      return `${this.$translations['check-in'].button.available} ${this.days} ${this.$translations['check-in'].button.days} `
    },
    score () {
      return (score) => {
        return Math.trunc(Number(score))
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.progress
  display: flex
  flex-direction: column
  gap: 33px
  justify-content: center
  align-items: flex-start
  background: white
  width: 100%
  &-track
    display: flex
    gap: 8px
    width: 100%
    overflow-x: auto
    padding: 0 16px
    margin: 0 -16px
    ::-webkit-scrollbar
      height: 10px
</style>
